




































import { Component, Vue, Watch, Prop, Emit } from 'vue-property-decorator';
import { AuditorResponse } from '@/models/poultry/auditor.model';
import { auditorStores } from '@/store/poultry/auditor/index';
import { ToastProgrammatic as Toast } from 'buefy';
import { clientStores } from '@/store/poultry/client';

@Component({
  components: {},
})
export default class CreateAuditorForm extends Vue {
  store = auditorStores.create;
  listStore = auditorStores.list;
  clientStore = clientStores.details;
  keywords: string = '';
  selected: number[] = [];

  created() {
    this.store.setKeywords('')
    this.store.fetchAllAuditors()
    this.store.fetchSelectedAuditors()
  }

  get auditors(): AuditorResponse[] {
    return this.store.allAuditors
  }

  @Watch('selected')
  updateCurrentSelected() {
    this.store.setSelectedAuditors(this.selected);
  }

  @Watch('store.selectedAuditors')
  updateSelected() {
    this.selected = this.store.selectedAuditors;
  }

  @Watch('store.isUpdated')
  async updatedSuccessfully() {
    if (this.store.isUpdated) {
      Toast.open({
        type: 'is-success',
        message: 'Auditor successfully updated',
      });
      this.closeCreateAuditorModal();
      this.listStore.fetchAll(this.listStore.defaultPagination);
      this.clientStore.fetchById(parseInt(this.$route.params.id));
    }
  }

  seachByClientEmail() {
    this.store.setKeywords(this.keywords)
    this.store.fetchAllAuditors()
  }

  @Emit('onClose')
  closeCreateAuditorModal() {}
}
