









import { Vue, Component } from 'vue-property-decorator';
import AuditorTableBody from './AuditorTableBody.vue';
import {auditorStores} from '@/store/poultry/auditor/';
import Pagination from '@/components/poultry/Pagination.vue'
import { PaginationModel } from '@/models/poultry/pagination.model';
import AuditorTableHeader from './AuditorTableHeader.vue';

@Component({
  components: {
    AuditorTableHeader,
    AuditorTableBody,
    Pagination
  }
})
export default class AuditorTable extends Vue {
  store = auditorStores.list;

  handlePageCountChanged(params: PaginationModel) {
    this.store.fetchAll(params);
  }
}
