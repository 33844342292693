





























import { Vue, Component, Prop } from 'vue-property-decorator';
import { AuditorDetailsResponse } from '@/models/poultry/auditor.model';

@Component({
  components: {},
})
export default class AuditorDetails extends Vue {
  @Prop()
  auditorDetails!: AuditorDetailsResponse;
}
