












import { Component, Vue } from 'vue-property-decorator';
import AuditorTable from './AuditorTable.vue';
import { auditorStores } from "@/store/poultry/auditor";
import ModalWithButtons from '@/components/poultry/ModalWithButtons.vue';
import CreateAuditorForm from "./CreateAuditorForm.vue";
import AuditorPageHeader from './AuditorPageHeader.vue';

@Component({
  components: {
    AuditorTable,
    ModalWithButtons,
    CreateAuditorForm,
    AuditorPageHeader
  }
})
export default class Auditor extends Vue {
  store = auditorStores.list;
  createAuditorStore = auditorStores.create;
  showCreateAuditModal = false;

  handleEditAuditorClicked() {
    this.showCreateAuditModal = true;
  }

  async searchByKeywords(inputKeywords: any) {
    if (!inputKeywords.trim()) {
      await this.store.fetchAll(this.store.defaultPagination);
      return;
    }
    this.store.searchByKeywords({
      keywords: inputKeywords,
    });
  }
}
