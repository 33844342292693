






























import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';

@Component({
  components: {
  }
})

export default class AuditorPageHeader extends Vue {
  keywords: string = '';

  @Emit('onSearch')
  searchByKeywords() {
    return this.keywords;
  }

  @Emit('onCreateNewAuditor')
  createNewAuditor() {}
}
