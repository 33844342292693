































import { Vue, Component } from 'vue-property-decorator';
import { auditorStores } from '@/store/poultry/auditor/index';
import SortIndicator from '@/components/poultry/SortIndicator.vue';

@Component({
  components: {
    SortIndicator
  }
})
export default class AuditorTableHeader extends Vue {
  store = auditorStores.list;

  sort(sortType: any) {
    this.store.setSortType(sortType);
  }

  get currentSort() {
    return this.store.currentSort;
  }

  get currentSortOrder() {
    return this.store.currentSortOrder.reverse;
  }
}
