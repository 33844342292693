













import { Vue, Component } from 'vue-property-decorator';
import Modal from '@/components/poultry/Modal.vue';
import AuditorDetails from '@/views/poultry/Client/Auditor/AuditorDetails.vue';
import { AuditorResponse, AuditorDetailsResponse } from '@/models/poultry/auditor.model';
import { auditorStores } from '@/store/poultry/auditor/index';
import { getRowNumber } from '@/utils/pagination';
import { axios } from '@/utils/axios';
import { Endpoints } from '@/store/poultry/endpoints';

@Component({
  components: {
    Modal,
    AuditorDetails,
  },
})
export default class AuditorTableBody extends Vue {
  listStore = auditorStores.list;
  detailStore = auditorStores.details;
  showEditAuditorModal: boolean = false;
  rowNumber = 1;
  auditorDetails = {} as AuditorDetailsResponse;
  isRetrievingAuditorDetails = false;

  async handleAuditorClicked(id: number) {
    this.isRetrievingAuditorDetails = true;
    try {
      const { data } = await axios.get(
        `${Endpoints.Clients}/${this.$route.params.id}/auditors/${id}`,
      );
      this.auditorDetails = data;
    } catch (error) {
      console.error(error);
    }
    this.isRetrievingAuditorDetails = false;
  }

  created() {
    this.listStore.fetchAll();
  }

  getRowNumber() {
    const startingRowNumber = getRowNumber(this.listStore.pagination);
    return startingRowNumber;
  }

  getAuditorFullName(auditor: AuditorResponse): string {
    return auditor.name ? auditor.name : ''
  }

  getAuditorPhoneNumber(auditor: AuditorResponse): string {
    return auditor.phoneNumber ? auditor.phoneNumber : ''
  }

  get auditors(): AuditorResponse[] {
    return this.listStore.sortedAuditors;
  }
}
