









































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
const ESC_KEY_CODE = 27

@Component({})
export default class ModalWithButtons extends Vue {
  @Prop()
  private store: any

  @Prop({
    type: Boolean
  })
  private showModal!: boolean

  @Prop({
    type: String
  })
  private title!: string

  @Prop({ type: Boolean, default: true })
  private showCloseButton!: boolean

  private element: any

  mounted() {
    this.element = document.getElementById('modal')
    this.initializeEventListeners()
  }

  @Emit('onClose')
  closeModal() {}

  initializeEventListeners() {
    if (!this.element) {
      return
    }
    document.body.addEventListener('keyup', this.onEscapeButtonClicked, true)
  }

  removeAllEventListeners() {
    document.body.removeEventListener('keyup', this.onEscapeButtonClicked, true)
    if (this.element) {
      this.element.removeEventListener('click', this.onModalBlurred, true)
    }
  }

  beforeDestroy() {
    this.removeAllEventListeners()
  }

  private onEscapeButtonClicked = (event: any) => {
    if (event.keyCode === ESC_KEY_CODE) {
      this.closeModal()
    }
  }

  private onModalBlurred = (event: any) => {
    this.closeModal()
  }

  save() {
    this.store.updateClientAuditor()
  }

  clear() {
    this.store.setSelectedAuditors([])
  }
}
